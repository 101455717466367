import {graphql, Link} from "gatsby";

import { renderRichText } from "gatsby-source-contentful/rich-text"
import get from "lodash/get";
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import React, {useMemo, useRef, useState} from "react";
import {LGGHeader, XSHeader} from "../components/typography";
import {
  Button,
  ButtonOnClick,
  VariantRedWithBorder,
  VariantWhiteOutlineRedHover
} from "../components/buttons";
import ScrollContainer from 'react-indiana-drag-scroll'

import './election-candidate-page.css'

import {SetActiveCarousel} from "../components/carousels";

const CandidateQandA = ({question, answer, hidden, candidate, next, nextQTag}) => (
  <div className={`font-body text-xl use-source-sans-pro ${hidden ? 'hidden' : ''}`}>
    <XSHeader className="text-yellow pt-8">Question</XSHeader>
    <div className="pb-8 pt-4 font-bold markdown">{renderRichText(question.questionText)}</div>
    <XSHeader className="text-yellow pt-8">Answer</XSHeader>
    <div className="pb-8 markdown pt-4">{renderRichText(answer)}</div>
    <div className="w-full flex flex-col-reverse md:flex-row justify-between text-base">
      <Link to={`/elections/${candidate.cycleRegion.cycleSlug}/`} className="pt-8 md:pt-0"><Button variant={VariantWhiteOutlineRedHover} className="px-6">All Candidates</Button></Link>
      <div><ButtonOnClick variant={VariantWhiteOutlineRedHover} className="px-6" onClick={next}>Next: {nextQTag}</ButtonOnClick></div>
    </div>
  </div>
)

const CandidatePageTemplate = ({data, location}) => {
  const [activeQIndex, setActiveQIndex] = useState(0);
  const qSelectorRef = useRef(null);
  const candidate = get(data, 'contentfulElectionsCandidate')
  // all candidates + the current one
  const allCandidatesInclusive = get(data, 'allContentfulElectionsCandidate.edges')
  const siteTitle = get(data, 'site.siteMetadata.title')

  const questionTags = useMemo(() => candidate.assessmentResponses.reduce((accumulator, value) => {
    accumulator.push(value.question.questionTag)
    return accumulator
  },[]), [candidate.assessmentResponses]);

  const nextCandidate = useMemo(() => {
    // we use this to be able to find where our current candidate is in the list of all candidates
    const findNextCandidateKey = cand => `${cand.cycleRegion.cycleSlug}-${cand.slug}`

    const pos = allCandidatesInclusive.map(e => findNextCandidateKey(e.node)).indexOf(findNextCandidateKey(candidate));

    return allCandidatesInclusive[pos === allCandidatesInclusive.length - 1 ? 0 : pos + 1].node

  }, [allCandidatesInclusive])

  const selectQ = questionTag => {
    setActiveQIndex(questionTags.indexOf(questionTag))
  }

  const nextQ = () => {
    const onLastQuestion = activeQIndex === questionTags.length - 1

    // make sure the highlighted question label is visible
    const scrollWidth = qSelectorRef.current.getElement().scrollWidth
    const scrollChunk = scrollWidth / questionTags.length // approximate width of each button.
    const scrollPosition = (activeQIndex * scrollChunk) + (scrollChunk / 2)

    qSelectorRef.current.getElement().scrollTo(onLastQuestion ? 0 : scrollPosition, 0);

    setActiveQIndex(onLastQuestion ? 0 : activeQIndex + 1)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <Layout location={location}>
      <Helmet title={`${candidate.name} | ${siteTitle}`} />
      <div className="px-8 pb-8 md:px-16 md:pb-16 text-white max-w-5xl mx-auto pt-40 candidate-page">
        <section className="pb-16 md:pb-24">
          <XSHeader className="text-yellow">{candidate.position}</XSHeader>
          <LGGHeader className="leading-extra-tight">{candidate.name}</LGGHeader>
        </section>
        <SetActiveCarousel activeSlideIndex={activeQIndex} className="hidden md:block">
          {candidate.assessmentResponses.map(({question}, index) => {
              const {questionTag} = question
              // const margin = index === 0 ? 'ml-4 md:ml-0 mr-4' : index === questionTags.length - 1 ? 'ml-4 mr-4 md:mr-0' : 'mx-4'
              const active = questionTags.indexOf(questionTag) === activeQIndex
              console.debug(`active: ${active}, label: ${questionTag}`)
              return (
                <div className="px-4 " key={index}>
                  <ButtonOnClick
                    className="mx-4 my-4"
                    variant={active ? VariantRedWithBorder : VariantWhiteOutlineRedHover}
                    onClick={() => selectQ(questionTag)}>
                    {questionTag}
                  </ButtonOnClick>
                </div>
              )
            }
          )}
        </SetActiveCarousel>
        <ScrollContainer className="md:hidden question-selector mb-16 md:mb-16 -mx-8 md:mx-0 flex overflow-visible" hideScrollbars={false} ref={qSelectorRef}>
            {candidate.assessmentResponses.map(({question}, index) => {
                const {questionTag} = question
                const margin = index === 0 ? 'ml-4 md:ml-0 mr-4' : index === questionTags.length - 1 ? 'ml-4 mr-4 md:mr-0' : 'mx-4'
                const active = questionTags.indexOf(questionTag) === activeQIndex
                console.debug(`active: ${active}, label: ${questionTag}`)
                return (
                  <ButtonOnClick
                    key={index}
                    variant={active ? VariantRedWithBorder : VariantWhiteOutlineRedHover}
                    className={`${margin} my-4`}
                    onClick={() => selectQ(questionTag)}>
                    {questionTag}
                  </ButtonOnClick>
                )
            })}
        </ScrollContainer>
        <section>
          {candidate.assessmentResponses.map((({question, answer}, index) =>
              <CandidateQandA
                question={question}
                answer={answer}
                next={nextQ}
                candidate={candidate}
                nextQTag={questionTags[activeQIndex === questionTags.length - 1 ? 0 : activeQIndex + 1]}
                hidden={question.questionTag !== questionTags[activeQIndex]}
                key={index}
              />
          ))}
        </section>
      </div>
      <section className="max-w-6xl py-16 px-8 md:px-20 bg-gray-300 mx-auto mb-8 md:mb-16 flex justify-between items-center">
        <div>
          <XSHeader className="text-yellow pt-4">Next Candidate</XSHeader>
          <LGGHeader className="leading-extra-tight mb-4">{nextCandidate.name}</LGGHeader>
        </div>
        <Link to={`/elections/${nextCandidate.cycleRegion.cycleSlug}/${nextCandidate.slug}`}>
          <img src="/imgs/rightarrow.svg" className="w-20 md:w-40"/>
        </Link>
      </section>
    </Layout>
  )
}

export default CandidatePageTemplate

export const pageQuery = graphql`
  query CandidateById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulElectionsCandidate {
      edges {
        node {
          name
          slug
          cycleRegion {
            cycleSlug
          }
        }
      }
    }
    contentfulElectionsCandidate(id: { eq: $id }) {
      name
      position
      cycleRegion {
        cycleSlug
      }
      slug
      assessmentResponses {
        question {
          questionTag
          questionText {
            raw
          }
        }
        answer {
          raw
        }
      }
    }
  }
`
